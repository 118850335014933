@use '../../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-s;

  label {
    margin-top: 0;
    > span {
      color: var(--text-color-secondary, rgba(9, 23, 35, 0.6));
      font-size: $font-size-small;
    }
  }
}

.text {
  display: inline-flex;
  flex-direction: column;
  margin-right: $spacing-s;
  flex-grow: 1;

  h2 {
    margin: 0;
  }

  p {
    margin: $spacing-xs 0 0;
    font-size: $font-size-small;
  }
}

@include mq($from: $desktop) {
  .content {
    max-width: 600px;
    margin: 0 $spacing-xl;
    padding: $spacing-xl $spacing-xl $spacing-l $spacing-xl;
  }

  .text {
    margin-bottom: 0;
    flex-grow: 0;
  }

  .button {
    height: fit-content;
    align-self: flex-start;
    width: $max-content-width;
  }
}
