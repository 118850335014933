.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction-horizontal {
  flex-direction: row;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
}

.direction-vertical {
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
}
