@use '../../tokens/globals' as *;

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 8px 16px;
  line-height: 1.4;
  font-size: 14px;
}

.status-info {
  background-color: var(--background-inverted, $color-grey800);
  color: var(--text-color-inverted, $color-white);
  font-weight: 300;
}

.status-info-light {
  background-color: var(--background-secondary, $color-grey200);
  color: var(--text-color-primary, $color-grey800);
}

.status-error {
  background-color: $color-red400;
  [data-theme='light-refreshed'] & {
    background-color: rgba($color-red2, 0.8);
  }

  color: var(--text-color-primary, $color-grey800);
}

.status-warning {
  background-color: $color-pale-orange;
  color: $color-black;
}
