@import '~@mondough/monzo-ui/src/tokens/globals';

.article {
  padding: $spacing-xs $spacing-s;
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  min-height: 60px;
}

.left-side-content {
  flex: 5;
  display: flex;
  gap: $spacing-s;
  align-items: center;
  overflow: hidden;
  height: 100%;
  min-height: 48px;
  text-align: left;
}

.money-symbol {
  display: none;
}

.money {
  font-weight: 500;
}

.money-minor {
  font-weight: 600;
}

.trailing-content:not(:empty) {
  flex: 3;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
  text-align: right;
}

.text-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-text {
  font-size: 15px;
  &.ellipsis {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.secondary-text {
  font-size: 12px;
}

.avatar {
  width: 32px;
  height: 32px;
}
