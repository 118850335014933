@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.overlay {
  background: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.container {
  z-index: 101;
  position: fixed;
  display: flex;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
}

.content {
  width: 100%;
  background-color: $color-white;
  padding: $spacing-l;
  border-top-right-radius: $border-radius-large;
  border-top-left-radius: $border-radius-large;
}

@include mq($from: $desktop) {
  .content {
    margin: 0 $spacing-m;
  }
}
