@use '../../scss/variables' as *;
@import '~@mondough/monzo-ui/src/tokens/globals';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 2rem auto;
  padding: $spacing-m;
  border-color: transparent;
  width: 100%;
  border-radius: 20px;

  @include mq($from: $desktop) {
    margin: 4rem auto;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
  }

  .text {
    font-size: 17px;
  }

  .avatar {
    width: 70px;
    height: 70px;
  }

  .title {
    max-width: 100%;
    overflow-wrap: break-word;
    font-size: $font-size-mobile-title;
    @include mq($from: $desktop) {
      font-size: $font-size-desktop-title;
    }
  }

  .text-input {
    margin-bottom: $spacing-s;
  }

  .label {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  button {
    &.secondary {
      background-color: $color-soft-white;
      border-color: transparent;
      color: $color-action-blue;
    }

    &:disabled {
      background-color: $color-grey4;
      color: $color-grey2;
    }
  }
}
