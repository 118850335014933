@use '@mondough/monzo-ui/src/tokens/globals' as *;

.avatar {
  position: relative;
  width: fit-content;

  .badge {
    width: $spacing-m;
    height: $spacing-m;

    position: absolute;
    bottom: -$spacing-xs;
    right: -$spacing-xs;

    border: 2px solid var(--_badge-border-color, #fff);

    > p {
      font-size: 8px;
    }
  }
}

.icon {
  path {
    fill: white;
  }
}

.square {
  border-radius: 6px;
  background-color: var(--_badge-border-color, #fff);
}

.circle {
  border-radius: 50%;
}
