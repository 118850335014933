$desktop: 1025px;
$header-height: 84px;
$large-mobile-viewport: 400px;
$max-content-width: 393px;
$font-size-mobile-title: 28px;
$font-size-mobile-marketing-text: 16px;
$font-size-desktop-title: 32px;
$font-size-desktop-marketing-text: 20px;
$border-radius-large: 37.3px;
$font-stack-title: 'MonzoSansDisplay', sans-serif;
$font-stack-subtitle: 'MonzoSansDisplay', sans-serif;
$font-stack-body: 'MonzoSansText', sans-serif;
