@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fullscreen {
  min-height: 400px;
  @include mq($from: $desktop) {
    min-height: 720px;
  }
}
