@use '../../scss/variables' as *;
@use '~@mondough/monzo-ui/src/tokens/globals' as *;

//Mobile-first layout

.app {
  --font-stack-title: 'MonzoSansDisplay', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background: $color-white;
}

.main {
  width: 100%;
  margin-top: calc(-1 * $header-height);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 0;
  justify-content: space-evenly;
}

.right-side-content {
  margin: 0 auto;
  height: fit-content;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

.left-side-content {
  display: flex;
  justify-content: center;
  padding: $spacing-m;
  padding-top: $header-height + 48px;
  padding-bottom: $spacing-s;
  height: 100%;
  width: 100%;
  background: rgb(195, 255, 52);
  background: linear-gradient(
    180deg,
    rgba(195, 255, 52, 1) 0%,
    rgba(242, 248, 243, 1) 150px,
    rgba(242, 248, 243, 1) 100%
  );
  border-radius: 0 0 $border-radius-large $border-radius-large;
  margin-bottom: $spacing-s;
}

.content {
  height: 100%;
  width: 100%;
  max-width: $max-content-width;
  padding-bottom: $spacing-m;

  &:empty {
    display: none;
  }
}

//MEDIA QUERIES

@include mq($from: $desktop) {
  .desktop-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .right-side-content {
    flex: 1;
    padding: $spacing-m;
    padding-top: $header-height + 48px;
    background-color: $color-white;
    border-radius: 0;
    max-width: unset;
    height: auto;
  }

  .left-side-content {
    flex: 1;
    margin-bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(195, 255, 52, 1) 0%,
      rgba(242, 248, 243, 1) 40%,
      rgba(242, 248, 243, 1) 100%
    );
    &:not(:only-child) {
      border-radius: 0 $border-radius-large $border-radius-large 0;
      .content {
        min-height: 720px;
      }
    }
  }
}

@include mq($from: wide) {
  .left-side-content:not(:only-child) {
    justify-content: flex-end;
    .content {
      margin-right: 120px;
    }
  }

  .right-side-content {
    justify-content: flex-start;
  }
}
