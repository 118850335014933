@use '~@mondough/monzo-ui/src/tokens/globals' as *;
@use '../../scss/variables' as *;

.content {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: $spacing-xs;
  grid-row-gap: $spacing-xs;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing-xs;
}

.text {
  display: inline-flex;
  flex-direction: column;
  margin-right: $spacing-s;
  margin-bottom: $spacing-m;

  h2 {
    margin: 0;
  }

  p {
    margin: $spacing-xs 0 0;
    a {
      font-size: $font-size-medium;
      text-decoration: underline;
    }
  }
}

@include mq($from: $desktop) {
  .content {
    margin: 0 $spacing-m;
    grid-row-gap: $spacing-m;
    height: 250px;
  }

  .text {
    margin-bottom: 0;
    grid-area: 1 / 1 / 2 / 2;
  }

  .preferences-button {
    grid-area: 2 / 1 / 3 / 2;
    height: fit-content;
    align-self: center;
    max-width: $max-content-width;
  }

  .button-group {
    grid-area: 1 / 4 / 3 / 3;
    justify-content: center;
    width: $max-content-width;
  }
}
