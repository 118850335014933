@use '../../tokens/globals' as *;

.skip-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: 9999;
  &:focus {
    width: auto;
    height: auto;
    overflow: visible;
    text-decoration: underline;
    background: lighten($color-tangerine500, 10%);
    color: $color-black;
    padding: $spacing-s;
  }
}
