@use '../../scss/variables' as *;
@import '~@mondough/monzo-ui/src/tokens/globals';

.wrapper {
  margin-bottom: 12px;
  box-shadow: none;
  border: none;
  flex: unset; // Remove the flex property from monzo-ui
  padding: 16px 0 $spacing-xs 0;
}

.money-symbol {
  display: none;
}

.money-major,
.money-minor {
  font-weight: 600;
  font-size: $font-size-small;
  font-family: MonzoSansText;
}

.money-amount {
  font-size: unset;
}

.avatar {
  width: 48px;
  height: 48px;
}

.people {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding: $spacing-s $spacing-xs $spacing-xs $spacing-s;
}

.person {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  background-color: $color-soft-white;
  width: 84px;
  padding: $spacing-xs;
  border-radius: $spacing-s;

  p {
    font-weight: 600;
  }

  .marquee {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;

    p {
      display: inline-block;
      animation: marquee 5s linear;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 15px;
  font-family: 'MonzoSansText';
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
  0% {
    transform: translate(0, 0);
  }
}
