@use 'base-theme' as *;
@use '_color' as *;
@use 'sass:color';

:root,
:root[data-theme='light'] {
  @include base-theme();
}

:root[data-theme='light-refreshed'] {
  @include base-refreshed-theme();
}

:root[data-theme='business-light'] {
  @include base-refreshed-theme();

  // Backgrounds
  --background-primary: #{$color-soft-white};
  --background-secondary: #{$color-white};
  --background-inverted: #{$color-navy1};

  --text-color-primary: #{$color-navy1};
  --text-color-secondary: #{color.adjust($color-navy1, $alpha: -0.4)};
  --text-color-inverted: #{$color-white};
  --text-color-disabled: #{color.adjust($color-navy1, $alpha: -0.4)};
  --text-color-positive: #{$color-green1};
  --text-color-warning: #{$color-yellow1};
  --text-color-action: #{$color-action-blue};

  --fill-primary: #{$color-action-blue};
  --fill-secondary: #{$color-white};
  --fill-negative: #{$color-red1};
  --fill-neutral: #{$color-white};
  --fill-positive: #{$color-green1};
  --fill-warning: #{$color-yellow1};
  --fill-disabled: #{$color-platinum3};

  --link-text-color: #{$color-action-blue};
  --link-text-color-hover: #{color.adjust($color-action-blue, $blackness: 20%)};
  --link-text-color-active: #{color.adjust($color-action-blue, $blackness: 20%)};

  --border-color-primary: #{color.adjust($color-navy2, $alpha: -0.8)};
  --border-color-secondary: #{color.adjust($color-navy2, $alpha: -0.8)};

  --primary-action-bg: #{$color-action-blue};
  --primary-action-border: transparent;
  --primary-action-text: #{$color-white};
  --primary-action-bg-hover: #{color.mix($color-action-blue, $color-black, 92%)};
  --primary-action-bg-active: #{color.mix($color-soft-white, $color-black, 92%)};
  --primary-action-bg-disabled: #{color.adjust($color-action-blue, $alpha: -0.6)};

  --secondary-action-bg: #{$color-white};
  --secondary-action-border: #{$color-action-blue};
  --secondary-action-text: #{$color-action-blue};
  --secondary-action-bg-hover: #{color.mix($color-soft-white, $color-black, 92%)};
  --secondary-action-bg-active: #{color.scale($color-grey200, $lightness: -10%)};
  --secondary-action-bg-disabled: #{$color-grey200};

  --tertiary-action-bg: #{$color-white};
  --tertiary-action-border: #{$color-transparent};
  --tertiary-action-text: #{$color-action-blue};
  --tertiary-action-bg-hover: #{color.mix($color-soft-white, $color-black, 92%)};
  --tertiary-action-border-hover: #{$color-transparent};
  --tertiary-action-bg-active: #{color.mix($color-soft-white, $color-black, 92%)};
  --tertiary-action-border-active: #{$color-grey400};
  --tertiary-action-border-selected: #{$color-sky500};
  --tertiary-action-bg-selected: #{rgba($color-sky500, 0.05)};

  --input-border-hover: #{$color-navy1};
  --input-border-focus: #{$color-action-blue};
  --input-border-invalid: #{$color-red1};
  --input-border-disabled: #{$color-grey3};
  --input-font-weight: 400;
  --input-text-disabled: var(--text-color-disabled);
  --input-label-text: var(--text-color-primary);
  --input-placeholder-text: #{$color-platinum3};
  --input-currency-placeholder-text: #{$color-platinum3};
  --input-background: #{$color-white};
  --input-background-invalid: #{rgba($color-red1, 0.02)};
  --input-background-disabled: #{$color-platinum3};
}
