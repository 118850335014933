@use '../../tokens/globals' as *;

.square {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.color {
    @include color-options('background-color');
  }
  &.size {
    @include size-options('min-width', 'min-height', 'width', 'height');
  }
}
