@use '../../tokens/globals' as *;

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: $spacing-m $spacing-xs;
  background-color: var(--background-overlay, rgba($color-grey400, 0.8));
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  &.blurOverlay {
    @supports (backdrop-filter: blur(16px)) {
      backdrop-filter: blur(16px);
    }

    // Ensure the content behind the dialog is still concealed
    @supports not (backdrop-filter: blur(16px)) {
      background-color: var(--background-overlay-opaque, $color-grey400);
    }
  }
}
