.switch {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;
}

.label-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  margin: 0;
}

.sublabel {
  font-size: 13px;
}
