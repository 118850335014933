@import '~@mondough/monzo-ui/src/tokens/globals';

.wrapper {
  display: inline-flex;
  border-radius: 4px;
  background-color: $color-grey500;

  &.default {
    // Legacy category colours
    &_blue {
      background-color: #00a4db;
    }
    &_green {
      background-color: #00ba4e;
    }
    &_dark-blue {
      background-color: #14233c;
    }
    &_teal {
      background-color: #00738c;
    }
    &_lime-green {
      background-color: #7ed321;
    }
    &_purple {
      background-color: #a561ff;
    }
    &_pink {
      background-color: #f30085;
    }
    &_beige {
      background-color: #942c00;
    }
    &_royal-blue {
      background-color: #003fc7;
    }
    &_grey {
      background-color: #707070;
    }
    &_yellow {
      background-color: #f59f00;
    }
    &_orange {
      background-color: #ff7847;
    }
    &_dark-yellow {
      background-color: #f1c430;
    }
    &_dark-teal {
      background-color: #277059;
    }
    &_red {
      background-color: #f00a0a;
    }
    &_dark-purple {
      background-color: #53368d;
    }
    &_dark-brown {
      background-color: #5a3f35;
    }
    &_dark-pink {
      background-color: #a40352;
    }
    &_matt-blue {
      background-color: #144673;
    }
    &_red-brown {
      background-color: #8e2520;
    }
    &_blue-green {
      background-color: #55bd9c;
    }
    &_light-blue {
      background-color: #85c1e9;
    }

    // Refreshed category colours
    &_navy_1 {
      background-color: $color-navy1;
    }
    &_navy_2 {
      background-color: $color-navy2;
    }
    &_navy_3 {
      background-color: $color-navy3;
    }
    &_navy_4 {
      background-color: $color-navy4;
    }
    &_grey_1 {
      background-color: $color-grey1;
    }
    &_grey_2 {
      background-color: $color-grey2;
    }
    &_grey_3 {
      background-color: $color-grey3;
    }
    &_grey_4 {
      background-color: $color-grey4;
    }
    &_soft_white {
      background-color: $color-soft-white;
    }
    &_deep_orange {
      background-color: $color-deep-orange;
    }
    &_mid_orange {
      background-color: $color-mid-orange;
    }
    &_pale_orange {
      background-color: $color-pale-orange;
    }
    &_deep_pink {
      background-color: $color-deep-pink;
    }
    &_mid_pink {
      background-color: $color-mid-pink;
    }
    &_pale_pink {
      background-color: $color-pale-pink;
    }
    &_deep_neon {
      background-color: $color-deep-neon;
    }
    &_mid_neon {
      background-color: $color-mid-neon;
    }
    &_pale_neon {
      background-color: $color-pale-neon;
    }
    &_deep_teal {
      background-color: $color-deep-teal;
    }
    &_mid_teal {
      background-color: $color-mid-teal;
    }
    &_pale_teal {
      background-color: $color-pale-teal;
    }
    &_deep_blue {
      background-color: $color-deep-blue;
    }
    &_mid_blue {
      background-color: $color-mid-blue;
    }
    &_pale_blue {
      background-color: $color-pale-blue;
    }
    &_deep_khaki {
      background-color: $color-deep-khaki;
    }
    &_mid_khaki {
      background-color: $color-mid-khaki;
    }
    &_pale_khaki {
      background-color: $color-pale-khaki;
    }
    &_action_blue {
      background-color: $color-action-blue;
    }
    &_red {
      background-color: $color-red1;
    }
    &_yellow {
      background-color: $color-yellow1;
    }
    &_green {
      background-color: $color-green1;
    }
  }
  > img {
    align-self: center;
    margin: 0 auto;
    filter: invert(1);
  }
}
