@import '~@mondough/monzo-ui/src/tokens/globals';

.wrapper {
  max-height: 300px;
  margin: 12px 0;
  padding: 0;
  padding-bottom: $spacing-xxs;
  box-shadow: none;
  border: none;
  flex: unset; // Remove the flex property from monzo-ui
}

.list-item {
  border-bottom: 1px solid $color-grey200;
  &:last-child {
    border-bottom: none;
  }
}

.list {
  overflow: auto;
}

.title {
  font-weight: 600;
  font-size: 15px;
  font-family: 'MonzoSansText';
}

.show-more {
  margin-bottom: 0;
  border-radius: 0;
  width: 100%;
  svg {
    display: none;
  }
  p {
    color: $color-action-blue;
  }
}
