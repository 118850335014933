@use 'color' as *;
@use 'font-stack' as *;
@use 'font-size' as *;
@use 'sass:color';

@mixin base-theme {
  // Actions
  --link-text-color: #{$color-sky500};
  --link-text-color-hover: #{color.scale($color-sky500, $lightness: -15%)};
  --link-text-color-active: #{color.scale($color-sky500, $lightness: -20%)};

  --primary-action-bg: #{$color-sky500};
  --primary-action-border: transparent;
  --primary-action-text: #{$color-white};
  --primary-action-bg-hover: #007cb4;
  --primary-action-bg-active: #006596;
  --primary-action-bg-disabled: #{$color-sky400};

  --secondary-action-bg: #{$color-white};
  --secondary-action-border: #{$color-grey400};
  --secondary-action-text: #{$color-grey600};
  --secondary-action-bg-hover: #{$color-grey200};
  --secondary-action-bg-active: #{color.scale($color-grey200, $lightness: -10%)};
  --secondary-action-bg-disabled: #{$color-grey200};

  --tertiary-action-bg: #{$color-white};
  --tertiary-action-border: #{$color-grey300};
  --tertiary-action-text: #{$color-grey600};
  --tertiary-action-bg-hover: #{$color-grey200};
  --tertiary-action-border-hover: #{$color-grey400};
  --tertiary-action-bg-active: #{color.scale($color-grey200, $lightness: -5%)};
  --tertiary-action-border-active: #{$color-grey400};
  --tertiary-action-border-selected: #{$color-sky500};
  --tertiary-action-bg-selected: #{rgba($color-sky500, 0.05)};

  --destructive-action-bg: #{$color-red500};
  --destructive-action-border: transparent;
  --destructive-action-text: #{$color-white};
  --destructive-action-bg-hover: #{color.scale($color-red500, $lightness: -10%)};
  --destructive-action-bg-active: #{color.scale($color-red500, $lightness: 10%)};
  --destructive-action-bg-disabled: #{$color-red400};

  // Form inputs
  --input-border: #{$color-grey400};
  --input-border-hover: #{color.scale($color-grey500, $lightness: 10%)};
  --input-border-focus: #{$color-sky500};
  --input-border-invalid: #{$color-hot-coral};
  --input-border-disabled: #{$color-grey400};
  --input-font-weight: 400;
  --input-text-disabled: #{$color-grey400};
  --input-placeholder-text: #{$color-grey400};
  --input-currency-placeholder-text: #{$color-grey500};
  --input-label-text: #{$color-grey700};
  --input-background: #{$color-white};
  --input-background-invalid: #{rgba($color-hot-coral, 0.02)};
  --input-background-disabled: #{$color-grey200};

  // Backgrounds
  --background-primary: #{$color-white};
  --background-secondary: #{$color-grey200};
  --background-inverted: #{$color-grey800};

  // Fills - used for icon backgrounds
  --fill-primary: #{$color-sky500};
  --fill-secondary: #{$color-grey800};
  --fill-negative: #{$color-red500};
  --fill-neutral: #{$color-grey500};
  --fill-positive: #{$color-grass500};
  --fill-warning: #{$color-tangerine500};
  --fill-disabled: #{rgba($color-grey800, 0.1)};

  // Border
  --border-color-primary: #{$color-grey300};
  --border-color-secondary: #{$color-grey200};

  // Typography
  --text-color-primary: #{$color-grey800};
  --text-color-secondary: #{$color-grey600};
  --text-color-inverted: #{$color-grey300};
  --text-color-disabled: #{$color-grey400};
  --text-color-destructive: #{color.scale($color-hot-coral, $lightness: -25%)};
  --text-color-positive: #{#008838}; // grass500's contrast is too poor
  --text-color-warning: #{$color-tangerine500};
  --text-color-action: #{$color-sky500};

  --font-weight-normal: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-avatar: 600;

  --font-stack-global: #{$font-stack-maison};
  --font-stack-title: #{$font-stack-title};
  --font-stack-body: #{$font-stack-maison};
  --font-stack-money: #{$font-stack-maison};

  --font-size-extra-small: #{$font-size-extra-small};
  --font-size-small: #{$font-size-small};
  --font-size-medium: #{$font-size-medium};
  --font-size-large: #{$font-size-large};
  --font-size-extra-large: #{$font-size-extra-large};

  // Shadows
  --dialog-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  --card-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px rgba(17, 17, 26, 0.1);

  // Avatar colours
  --avatar-1-text: #f5b946;
  --avatar-1-bg: #fdf1da;
  --avatar-2-text: #eb824a;
  --avatar-2-bg: #fbe6db;
  --avatar-3-text: #e64b5f;
  --avatar-3-bg: #fadbdf;
  --avatar-4-text: #f09696;
  --avatar-4-bg: #fceaea;
  --avatar-5-text: #b982ff;
  --avatar-5-bg: #f1e6ff;
  --avatar-6-text: #e1b981;
  --avatar-6-bg: #f9f1e6;
  --avatar-7-text: #1e788c;
  --avatar-7-bg: #d2e4e8;
  --avatar-8-text: #9abba7;
  --avatar-8-bg: #ebf1ed;

  // Date input
  --date-button-background: #{$color-grey200};
  --date-button-background-hover: hsl(216, 80%, 92%);
  --date-button-background-focus: #{$color-grey500};
  --date-button-background-selected: hsl(216, 80%, 92%);
  --date-button-background-disabled: #{$color-white};
  --date-button-text-disabled: #{$color-grey400};
  --date-button-border-selected: #{$color-grey600};

  // Notice
  --notice-background-warning: #fff8f8;
  --notice-background-info: rgba(133, 193, 233, 0.05);
  --notice-background-success: rgba(52, 199, 89, 0.05);
  --notice-border-warning: #{$color-red400};
  --notice-border-info: rgba(41, 145, 204, 0.19);
  --notice-border-success: #{$color-green1};
}

@mixin base-refreshed-theme {
  // Actions - TODO all other buttons
  --link-text-color: #{$color-action-blue};
  --link-text-color-hover: #{color.scale($color-action-blue, $lightness: -15%)};
  --link-text-color-active: #{color.scale($color-action-blue, $lightness: -20%)};

  --primary-action-bg: #{$color-action-blue};
  --primary-action-border: transparent;
  --primary-action-text: #{$color-white};
  --primary-action-bg-hover: #{color.scale($color-action-blue, $lightness: -10%)};
  --primary-action-bg-active: #{color.scale(
      $color-action-blue,
      $lightness: -10%
    )};
  --primary-action-bg-disabled: #{color.scale(
      $color-action-blue,
      $lightness: -20%
    )};

  // Form inputs
  --input-border: #{$color-grey3};
  --input-border-hover: #{$color-navy4};
  --input-border-focus: #{$color-action-blue};
  --input-border-invalid: #{$color-hot-coral};
  --input-border-disabled: #{$color-grey3};
  --input-font-weight: 400;
  --input-text-disabled: #{$color-grey3};
  --input-label-text: #{$color-navy2};
  --input-placeholder-text: #{$color-grey3};
  --input-currency-placeholder-text: #{$color-grey1};
  --input-background: #{$color-white};
  --input-background-invalid: #{rgba($color-hot-coral, 0.02)};
  --input-background-disabled: #{rgba($color-grey1, 0.06)};

  // Backgrounds
  --background-primary: #{$color-white};
  --background-secondary: #{$color-soft-white};
  --background-inverted: #{$color-navy2};

  // Fills - used for icon backgrounds
  --fill-primary: #{$color-action-blue};
  --fill-secondary: #{$color-navy1};
  --fill-negative: #{$color-red1};
  --fill-neutral: #{$color-grey1};
  --fill-positive: #{$color-green1};
  --fill-warning: #{$color-yellow1};
  --fill-disabled: #{$color-grey3};

  // Border
  --border-color-primary: rgba(59, 76, 84, 0.2); // 20%  navy4
  --border-color-secondary: #{$color-soft-white};

  // Typography
  --text-color-primary: #{$color-navy1};
  --text-color-secondary: #{rgba($color-navy1, 0.6)};
  --text-color-inverted: #{$color-white};
  --text-color-disabled: #{$color-grey3};
  --text-color-destructive: #{color.scale($color-red2, $lightness: -25%)}; // supposed to be $color-red2 but the contrast is too poor
  --text-color-positive: #{#008739}; // supposed to be $color-green1 but the contrast is too poor
  --text-color-warning: #{$color-yellow1};
  --text-color-action: #{$color-action-blue};

  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --font-weight-avatar: 700;

  --font-stack-global: #{$font-stack-monzo-text};
  --font-stack-title: #{$font-stack-oldschool-grotesk};
  --font-stack-body: #{$font-stack-monzo-text};
  --font-stack-money: #{$font-stack-monzo-display};

  // Shadows
  --dialog-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  --card-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px rgba(17, 17, 26, 0.1);

  // Avatar colours
  --avatar-1-text: #{$color-soft-white};
  --avatar-1-bg: #{$color-hot-coral};
  --avatar-2-text: #{$color-mid-teal};
  --avatar-2-bg: #{$color-mid-neon};
  --avatar-3-text: #{$color-pale-blue};
  --avatar-3-bg: #{$color-deep-khaki};
  --avatar-4-text: #{$color-pale-pink};
  --avatar-4-bg: #{$color-mid-teal};
  --avatar-5-text: #{$color-hot-coral};
  --avatar-5-bg: #{$color-pale-blue};
  --avatar-6-text: #{$color-mid-orange};
  --avatar-6-bg: #{$color-pale-pink};

  // Date input - TODO
  // Notice - TODO
}
