@use '../../../scss/_variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.inner {
  padding: 32px;
  width: 100%;
  max-width: calc($max-content-width + 32px * 2);
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  min-height: unset;

  > * {
    color: $color-black;
  }

  img {
    display: none;
  }

  .title {
    font-size: $font-size-mobile-title;
  }

  .text {
    font-size: $font-size-medium;
    font-weight: 400;
  }

  .button {
    margin-top: $spacing-m;
    &:hover,
    &:active {
      background-color: var(--primary-action-bg-hover, #007cb4);
    }
    background-color: $color-action-blue;
    color: $color-white;
    border-color: transparent;
  }

  .trust-grid {
    margin: $spacing-s auto;
    align-items: center;
    justify-items: center;
    gap: $spacing-s;
    img {
      max-height: 100%;
      height: 45px;
      width: auto;
      align-self: flex-end;
    }
  }
}

@include mq($from: $desktop) {
  .inner {
    padding: $spacing-xxl;
    gap: $spacing-s;
    min-width: calc($max-content-width + 32px * 2);
    max-width: 550px;
    min-height: 720px;

    > * {
      color: $color-black;
    }

    .illustration {
      display: block;
      height: 208px;
      object-fit: cover;
      border-radius: 20px;
      width: 100%;
    }

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }

    .title {
      font-size: $font-size-desktop-title;
      font-weight: 800;
    }

    .button {
      margin-top: 0;
      background-color: $color-white;
      &:hover,
      &:active {
        background-color: $color-grey4;
      }
      color: $color-mid-teal;
      border-color: $color-mid-teal;
    }
  }
}

@include mq($from: wide) {
  .inner {
    margin-left: 48px;
  }
}
