@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

//Mobile-first layout

.container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.breadcrumb {
  align-self: flex-start;
  padding: 12px 0 !important;
  margin-top: -$spacing-xl;
  font-weight: 400;
}

.heading {
  font-size: $font-size-mobile-title;
  margin-bottom: $spacing-s;
  margin-left: $spacing-xs;
}

.card {
  background: $color-white;
  border-radius: $border-radius-large;
  padding: $spacing-m $spacing-s;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
  }

  p,
  label {
    margin-bottom: $spacing-s;
    font-size: $font-size-small;
  }

  label {
    font-weight: bold;
  }
}

@include mq($from: $desktop) {
  .container {
    margin-left: -$spacing-m;
  }

  .heading {
    font-size: $font-size-desktop-title;
  }

  .card {
    padding: $spacing-l $spacing-m;
    width: 450px;

    form {
      gap: $spacing-m;
    }

    p,
    label {
      margin-bottom: $spacing-m;
      font-size: $font-size-medium;
    }
  }
}
