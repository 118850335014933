.wrapper {
  position: relative;

  .symbol {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 0.5rem;

    &.large {
      font-size: 1.5rem;
    }
  }

  .input {
    width: 100%;
    &.has-symbol {
      padding-left: 2.5rem;
    }

    &.large {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}
