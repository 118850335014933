@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.header {
  width: 100%;
  max-width: 86em;
  margin: 0 auto;
  padding: 0 $spacing-m;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  overflow: hidden;

  top: 0;

  .trailing-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-grow: 1;
  }

  @mixin button {
    position: relative;
    font-weight: $font-weight-semi-bold;
  }

  .mobile-button {
    @include button;
    font-size: 13px;
    @include mq($from: $desktop) {
      font-size: 14px;
    }
  }

  .desktop-button {
    @include button;
    @include mq($from: $desktop) {
      color: $color-action-blue;
    }
  }

  .logo,
  .logo > img {
    margin-right: $spacing-xs;
    position: relative;
    cursor: pointer;
    height: 19px;
    max-width: unset;
    @include mq($from: $desktop) {
      height: 21px;
    }
  }
}
