@use '../../scss/variables' as *;
@import '~@mondough/monzo-ui/src/tokens/globals';

.button {
  min-width: 28px; // For small screens
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: $color-grey4;
  color: $color-action-blue;
  border-color: transparent;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
}

.input {
  max-width: 72px;
  font-size: 13px;
  height: 26px;
  padding: 0;
  text-align: center;
  border: 1px solid transparent;
  border-bottom-color: $color-action-blue;
  color: $color-action-blue;
  font-weight: 700;
  border-radius: 0;

  &:hover {
    border: 1px solid $color-action-blue;
    &:read-only {
      border: 1px solid transparent;
    }
  }

  &:read-only {
    color: $color-action-blue;
  }
}

.quantity {
  display: flex;
  gap: 6px;
}

@include mq($from: $desktop) {
  .input {
    font-size: 14px;
    max-width: 80px;
  }
  .quantity {
    gap: 12px;
  }
}
