@use '../../tokens/globals' as *;

.switch {
  display: inline-block;
}

.hiddenCheckbox {
  @include visually-hidden;

  &:checked + .track {
    background-color: var(--fill-primary, $color-sky500);

    .thumb {
      transform: translateX(9px);
    }
  }

  &:focus-within + .track {
    @include focus-ring-focus;

    [data-whatintent='touch'] &,
    [data-whatintent='mouse'] & {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }

  &:disabled + .track {
    cursor: default;
    opacity: 0.7;
  }
}

.track {
  width: 51px;
  height: 31px;
  background-color: var(--input-fill-disabled, $color-grey300);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding: 0 2px;
  cursor: pointer;
}

.thumb {
  background: $color-white; // I've not defined a CSS Variable for this because it's always white right now.
  width: 27px;
  height: 27px;
  transform: translateX(-9px);
  transition: transform 200ms ease;
  border-radius: 9999px;
  display: block;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}
