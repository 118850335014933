@use '../../tokens/globals' as *;
@use 'sass:color';

.radio-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.label {
  font-size: 16px;
  font-weight: var(--font-weight-normal, $font-weight-normal);
  color: var(--input-label-text, $color-grey700);
  margin-left: 8px;
  margin-top: 4px;
}

[data-whatintent='mouse'] .hiddenCheckbox,
[data-whatintent='touch'] .hiddenCheckbox {
  &:focus + .checkbox,
  &:active + .checkbox {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--input-border-focus, $color-sky500);
  }
}

.hiddenRadio {
  // https://moderncss.dev/pure-css-custom-checkbox-style/#step-1-hide-the-native-checkbox-input
  -webkit-appearance: none;
  appearance: none;
  // For iOS < 15 to remove gradient background
  background-color: transparent;
  // Margin isn't removed from the native checkbox input styles when using appearance: none
  margin: 0;

  &:checked + .radio {
    border: 2px solid var(--fill-primary, $color-sky500);
    position: relative;
    &::after {
      background-color: var(--fill-primary, $color-sky500);
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 12px;
      border: 4px solid var(--input-background, $color-white);
    }
  }

  &:not(:disabled):not(:checked):hover + .radio {
    border: 1px solid
      var(--input-border-hover, color.scale($color-grey500, $lightness: 10%));
  }

  &:focus-visible + .radio {
    @include focus-ring-focus;
  }

  &:disabled + .radio {
    background-color: var(--fill-disabled, #c2c8d0);
    border-color: var(--fill-disabled, #c2c8d0);
    &::after {
      background-color: var(--fill-disabled, #c2c8d0);
    }
  }
}

.radio {
  color: $color-white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--input-border, $color-grey400);
  background-color: var(--input-background, $color-white);
  display: flex;
  align-items: center;
  justify-content: center;

  &.invalid {
    background: var(--input-background-invalid, rgba($color-hot-coral, 0.02));
    border: 1px solid var(--input-border-invalid, $color-hot-coral);
  }
}
