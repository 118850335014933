@use '@mondough/monzo-ui/src/tokens/globals' as *;
@use 'variables' as *;

:root {
  .custom-scrollbar {
    scrollbar-width: unset;
    scrollbar-color: unset;

    &::-webkit-scrollbar {
      height: 1rem;
      width: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey1;
      border: 6px solid $color-white;
      background-clip: padding-box;
      border-radius: 9999px;
    }

    &::-webkit-scrollbar-track {
      background: $color-white;
      border: 6px solid $color-white;
    }
  }

  .mobile-only {
    display: flex !important;
    @include mq($from: $desktop) {
      display: none !important;
    }
  }

  .desktop-only {
    display: none !important;
    @include mq($from: $desktop) {
      display: flex !important;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

a,
button,
textarea,
select,
input {
  @include focus-ring;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'MonzoSansDisplay', sans-serif;
}

ol,
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// make the focus ring fit round the input
input[type='radio'] {
  border-radius: 50%;
}

input,
button,
textarea,
select {
  font: inherit;
}

//
// Anti-phishing tracking pixel. This helps us detect when a phisher copies our sites.
// See https://www.notion.so/monzo/Anti-phishing-trackers-b225565c2a0642a5993062ce8b9add11
//
html {
  background-image: url('https://internal-api.monzo.com/static/monzo_framework.png');
}

body {
  margin: 0;
  font-size: $font-size-medium;
  font-family: 'MonzoSansText', sans-serif;
}

button,
a {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  color: inherit;
  font-size: 18px;

  &:disabled {
    --primary-action-bg-disabled: #e3ebe4;
    background-color: $color-grey4;
    color: $color-grey2;
    cursor: not-allowed;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
}

a {
  color: #2991cc;
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.2;
}

[data-whatinput='mouse'] a,
[data-whatinput='mouse'] button,
[data-whatinput='mouse'] input,
[data-whatinput='mouse'] select,
[data-whatinput='mouse'] textarea,
[data-whatinput='touch'] a,
[data-whatinput='touch'] button,
[data-whatinput='touch'] input,
[data-whatinput='touch'] select,
[data-whatinput='touch'] textarea {
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    border: 1px solid transparent;
    box-shadow: none !important;
  }
}
