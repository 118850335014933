@use '../../tokens/globals' as *;

[data-whatintent='touch'] .textarea,
[data-whatintent='mouse'] .textarea {
  &:active,
  &:focus,
  &:active:focus {
    border: 1px solid var(--input-border-focus, $color-sky500);
  }
}

// We have this container so that we can autosize using CSS grid. More about this solution:
// https://css-tricks.com/auto-growing-inputs-textareas/
.container {
  display: inline-grid;
  width: 100%;
  align-items: stretch;

  &.size {
    @include size-options('font-size');
  }

  &.padding-regular::after {
    margin: 12px 16px;
  }

  &.padding-none::after {
    margin: 0;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
    grid-area: 1 / 1;
    border: 1px solid transparent;
    font-size: inherit;
  }
}

.textarea {
  background-color: var(--input-background, $color-white);
  border: 1px solid var(--input-border, $color-grey400);
  border-radius: 6px;
  font-size: 16px;
  font-weight: var(--input-font-weight, 400);
  width: auto;
  font-family: var(--font-stack-body, $font-stack-maison);
  grid-area: 1 / 1;

  &.size {
    @include size-options('font-size');
  }

  &.padding-regular {
    padding: 12px 16px;
  }

  &.padding-none {
    padding: 0;
  }

  &.border-transparent {
    border-color: transparent;
  }

  &::placeholder {
    color: var(--input-placeholder, $color-grey400);
  }

  &:hover {
    border: 1px solid var(--input-border-hover, lighten($color-grey500, 10%));
  }

  &:not(:disabled) {
    &:focus,
    &:active {
      border: 1px solid var(--input-border-focus, $color-sky500);
      &:hover {
        border: 1px solid var(--input-border-focus, $color-sky500);
      }
    }
  }

  &:disabled {
    border: 1px solid var(--input-border-disabled, $color-grey400);
    background-color: var(--input-background-disabled, $color-grey200);
  }

  &:not(:focus).invalid {
    border: 1px solid var(--input-border-invalid, $color-hot-coral);
    background: var(--input-background-invalid, rgba($color-hot-coral, 0.02));
  }
}
