@use '../../scss/variables' as *;
@import '~@mondough/monzo-ui/src/tokens/globals';

.footer {
  width: 100%;
  background-color: $color-navy1;
  flex-grow: 1;

  .inner {
    margin: 0 auto;
    max-width: 86em;
    padding: 24px 32px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 0px;
    grid-row-gap: 32px;
  }

  @include mq($from: $desktop) {
    border-radius: 20px 20px 0 0;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: min($max-content-width, 100%);
  gap: $spacing-s;
  margin: 0 auto;
}

.download {
  @extend .container;

  @include mq($until: $desktop) {
    grid-area: 4 / 1 / 5 / 3;
  }
}

.text {
  @include mq($until: $desktop) {
    grid-area: 3 / 1 / 4 / 3;
  }

  @include mq($from: $desktop) {
    grid-area: 2 / 1 / 3 / 3;
  }
}

.link {
  color: var(--text-inverted, $color-white);
  text-decoration: underline;
  margin-right: $spacing-s;
  padding: 0;
}

.links {
  display: flex;
  justify-content: flex-start;

  @include mq($until: $desktop) {
    grid-area: 2 / 1 / 3 / 3;
  }

  @include mq($from: $desktop) {
    margin-top: $spacing-s;
    grid-area: 1 / 1 / 2 / 3;
  }
}
